<template>
  <div>
    <!-- Form -->
    <b-row>
      <b-col>
        <b-card>
          <!-- Store Lat-Long -->
          <b-form-group
            description="Set/Reset Store Location using Map"
            label="Store Location (Lat-Long)"
            label-for="storeUrlName"
            label-cols-sm="4"
            label-cols-lg="3"
          >
            <b-input-group>
              <b-form-input
                type="text"
                placeholder="Set Lat-Long"
                :value="readOnlyLocation"
                trim
                disabled
              />
              <b-input-group-append>
                <b-button @click="setLatLongUsingMap">
                  <b-icon-geo-alt-fill />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <b-form-group
            label="Store Address"
            label-for="storeAddress"
            :invalid-feedback="getErrorMessage(errors, 'storeAddress')"
            :state="getErrorState(errors, 'storeAddress')"
            label-cols-sm="4"
            label-cols-lg="3"
          >
            <b-form-textarea
              :state="getErrorState(errors, 'storeAddress')"
              id="storeAddress"
              v-model="form.storeAddress"
              placeholder="Store Address"
              rows="3"
              max-rows="3"
              no-resize
            />

            <p v-if="enableGeocoding" class="my-2">
              <a href="#" @click.prevent="setAddressUsingStoreLocation">
                Click here
              </a>
              to set address using Store Location (Lat-Long)
            </p>
          </b-form-group>

          <!-- Buttons -->
          <div class="text-center">
            <b-button @click="save" class="mr-2" variant="primary">
              Save
            </b-button>
            <b-button @click="cancel"> Cancel </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <!-- Google Map Modal -->
    <b-modal v-model="showMapModal" size="xl" hide-header hide-footer>
      <StoreGMapLocator
        :lat="form.latitude"
        :lng="form.longitude"
        @success="onSuccessOfLocation"
        @cancel="closeModal"
      />
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { MAP_API_KEY } from "@/google-map-vue";

import { updateDeliverySettings } from "../../../apis/merchant";

import StoreGMapLocator from "../../../components/store/StoreGMapLocator.vue";

const RADIUS_MIN = 200;

export default {
  components: { StoreGMapLocator },
  created() {
    this.initDeliverySettings(this.token);
    this.initForm();
  },
  data() {
    return {
      // Api Key
      enableGeocoding: false,
      apiKey: MAP_API_KEY,

      // Modal
      showMapModal: false,

      // Modal
      form: {
        storeAddress: "",
        latitude: 0.0,
        longitude: 0.0,
      },
      errors: null,

      // place
      placeSelected: null,
    };
  },
  computed: {
    ...mapGetters({
      token: "login/getToken",
      store: "merchant/store/getStore",
      deliverySettings: "merchant/deliverySettings/getSettings",
    }),
    readOnlyLocation() {
      const latitude = this.form.latitude;
      const longitude = this.form.longitude;
      if (latitude || longitude) {
        return `${latitude},${longitude}`;
      }
      return null;
    },
    storeExists() {
      return this.store ? true : false;
    },
  },
  methods: {
    ...mapActions({
      updateStoreAddress: "merchant/store/updateStoreAddress",
      initDeliverySettings: "merchant/deliverySettings/initSettings",
      setDeliverySettings: "merchant/deliverySettings/setSettings",
    }),

    // Init form
    initForm() {
      if (this.storeExists) {
        this.form.storeAddress = this.store.storeAddress;
        this.form.latitude = this.store.latitude;
        this.form.longitude = this.store.longitude;
      }
    },

    // Button to set location using G-map
    setLatLongUsingMap() {
      // open map
      this.showMapModal = true;
    },

    // Link to set address using lat-long
    setAddressUsingStoreLocation() {
      if (!this.enableGeocoding) return;
      const latitude = this.form.latitude;
      const longitude = this.form.longitude;
      if (latitude || longitude) {
        // location selected basis on place
        if (this.placeSelected) {
          this.form.storeAddress = this.placeSelected.formatted_address;
        } else {
          // make geocoding request
          this.makeGeocodingRequest(latitude, longitude).then((data) => {
            // set form address
            if (data) {
              this.form.storeAddress = data.formatted_address;
            }
          });
        }
      }
    },

    // BUTTONS
    save() {
      // Update Address
      this.errors = null;
      if (this.storeExists) {
        this.updateStoreAddress(this.form)
          .then(this.onUpdateStoreSuccess)
          .catch(({ data }) => {
            this.errors = data.errors;
          });
      }
    },
    cancel() {
      this.$router.go(-1);
    },
    onUpdateStoreSuccess(data) {
      // on update of store address, update delivery center location (till business logic to separate delivery center point)
      const radius = this.deliverySettings
        ? this.deliverySettings.radius
        : RADIUS_MIN;
      this.updateNewDeliveryAreaSetting(data.latitude, data.longitude, radius);

      // go back
      this.cancel();
    },

    // StoreGMapLocator
    onSuccessOfLocation({ position, place }) {
      this.showMapModal = false;
      if (position) {
        this.form.latitude = position.lat;
        this.form.longitude = position.lng;
      }

      this.placeSelected = place;
    },
    closeModal() {
      this.showMapModal = false;
    },

    // GeoDecoding
    makeGeocodingRequest(lat, lng) {
      const apiKey = this.apiKey;
      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
      return axios
        .create({
          headers: null,
          params: null,
        })
        .get(url)
        .then((resp) => {
          const data = resp.data;
          if (data) {
            if (data.results.length > 0) {
              return data.results[0];
            }
          }
          return null;
        });
    },

    // Delivery Setting
    updateNewDeliveryAreaSetting(latitude, longitude, radius) {
      const areaForm = { latitude, longitude, radius };
      updateDeliverySettings(null, areaForm, this.token).then((data) => {
        this.setDeliverySettings(data);
      });
    },
  },
};
</script>
