<template>
  <div>
    <!-- Input (Places) -->
    <gmap-autocomplete
      @place_changed="setPlace"
      class="form-control my-3"
      placeholder="Search a location"
    />

    <!-- Map -->
    <gmap-map
      ref="storeGMapLocator"
      :center="center"
      :zoom="zoom"
      @zoom_changed="onZoomChanged"
      @click="mapClick"
      class="storeGMapLocator"
    >
      <gmap-marker
        v-if="currentPosition"
        :position="currentPosition"
        @click="onMarkerClick"
        @dragend="onMarkerClick"
        draggable
      ></gmap-marker>

      <!-- Location Button -->
      <b-button
        @click="getCurrentGeolocation"
        ref="gmapLocationButton"
        class="mr-2"
        variant="light"
      >
        <b-icon-geo-fill />
      </b-button>
    </gmap-map>

    <p class="text-muted text-center">
      Drag the marker/Click on the map to change location.
    </p>

    <!-- Buttons -->
    <div class="text-center">
      <b-button @click.prevent="save" class="mr-2" variant="primary">
        Save
      </b-button>
      <b-button @click.prevent="cancel"> Cancel </b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["lat", "lng"],
  mounted() {
    if (this.lat || this.lng) {
      this.initMap(false);
      this.setCurrentPosition({ lat: this.lat, lng: this.lng });
    } else {
      this.initMap(true);
    }
  },
  data() {
    return {
      // References
      google: null,
      map: null,

      // Map setting
      center: { lat: 0, lng: 0 },
      zoom: 15,

      // Current Position
      currentPosition: null,

      // Current Place
      currentPlace: null,
    };
  },
  methods: {
    // Map
    initMap: function (requestCurrentLocation) {
      // set references
      this.$refs.storeGMapLocator.$mapPromise.then((currentMap) => {
        this.map = currentMap;
        this.google = window.google;
        this.createCurrentLocationButton();

        if (requestCurrentLocation) {
          this.getCurrentGeolocation();
        }
      });
    },
    onZoomChanged(newZoom) {
      this.zoom = newZoom;
    },
    mapClick(data) {
      // to set location of marker
      this.onMarkerClick(data);
    },

    // Current Location Button
    createCurrentLocationButton() {
      const locationButton = this.$refs.gmapLocationButton;
      this.map.controls[this.google.maps.ControlPosition.RIGHT_BOTTOM].push(
        locationButton
      );
    },

    // Marker events
    onMarkerClick(item) {
      const lat = item.latLng.lat();
      const lng = item.latLng.lng();
      this.setCurrentPosition({ lat, lng });
    },

    // Browser Geolocation
    getCurrentGeolocation() {
      if (navigator.geolocation) {
        // make request to get current location
        navigator.geolocation.getCurrentPosition(
          this.onGeolocationSuccess,
          () => {
            // has error
            this.handleLocationError(true);
          }
        );
      } else {
        // Browser doesn't support Geolocation
        this.handleLocationError(false);
      }
    },
    handleLocationError(browserHasGeolocation) {
      // show error for no support
      const message = browserHasGeolocation
        ? "Error: The Geolocation service failed."
        : "Error: Your browser doesn't support geolocation.";
      alert(message);
    },
    onGeolocationSuccess(position) {
      // position received
      const pos = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      // set position
      this.setCurrentPosition(pos);
    },

    // PLACES
    // receives a place object via the autocomplete component
    setPlace(place) {
      if (place) {
        const location = place.geometry.location;
        if (location) {
          const position = {
            lat: location.lat(),
            lng: location.lng(),
          };
          this.setCurrentPosition(position);
          this.currentPlace = place;
        }
      }
    },

    //  Position
    setCurrentPosition(position) {
      this.currentPosition = position;
      this.center = position;
    },

    // Buttons
    save() {
      this.$emit("success", {
        position: this.currentPosition,
        place: this.currentPlace,
      });
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style>
.storeGMapLocator {
  width: 100%;
  height: 400px;
}
.pac-container {
  z-index: 2000;
}
</style>